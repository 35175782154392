@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Prociono&display=swap");
body h1,
p,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}
a {
  text-decoration: none;
}

.btn_ul {
  list-style: none;
  margin-bottom: 0px;
}
.btn_ul button {
  background: #14287e;
  padding: 7px 30px;
  border-radius: 100px;
  color: white;
  font-size: 18px;
}
.btn_ul button:hover {
  background: transparent;
  border: 1px solid #14287e;
  color: #14287e;
}
.nav-item .nav-link {
  color: black;
  font-size: 18px;
  font-weight: 500;
}
.banner-section {
  background-image: linear-gradient(
      200deg,
      rgb(133 216 227 / 23%) 30%,
      rgb(133 216 227 / 23%) 109%,
      rgba(93, 183, 254, 0) 12%,
      rgba(93, 183, 254, 0) -6%,
      rgba(93, 183, 254, 0) 22%,
      rgba(110, 233, 108, 0) 58%
    ),
    url("../../assests/globalimages/banner_img.png");
  height: 700px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: contain;
  /* padding: 0px 120px; */
  display: flex;
  height: 100vh;
  align-items: center;
  margin-top: 77px;
}
.banner-heading {
  font-family: "Kumbh Sans";
  height: auto;
  width: auto;
  color: #2c2c60;
  text-decoration: none;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 80px;
  letter-spacing: -4px;
  font-weight: 800;
  font-size: 70px;
  backdrop-filter: none;
  filter: none;
  transform-origin: 50% 50%;
  opacity: 1;
  transform: translate(0px, 0px);
  visibility: visible;
  margin-bottom: 70px;
}
.hme_bnr_text h1 {
  line-height: 60px;
  /* letter-spacing: -4px; */
  font-weight: 800;
  font-size: 48px;
  color: white;
}

.hme_bnr_text h1 span {
  color: rgba(56, 182, 255, 1);
}
.hme_bnr_text p {
  width: 80%;
  font-size: 22px;
}
.see_how_w {
  background-color: #5bb3ff;
  border: 2px solid #5bb3ff;
  border-radius: 100px;
  transition: 0.3s;
  padding: 13px 30px;
  font-size: 18px;
  color: white;
  font-weight: 500;
}
.see_how_w:hover {
  background: transparent;
  border: 2px solid #5bb3ff;
  color: #5bb3ff;
}
.service_btn {
  background: transparent;
  border: 2px solid white;
  border-radius: 100px;
  transition: 0.3s;
  font-size: 18px;
  color: white;
  font-weight: 500;
}
.service_btn:hover {
  background: white;
  color: #5bb3ff;
}
.sect2 {
  background-color: #ddf9ff66;
  padding: 40px 0px;
}
.sect2 h3 {
  font-weight: 600;
}
.sect2 h1 {
  font-size: 48px;
  font-weight: 800;
  margin-top: 20px;
}
.sect3 {
  padding: 50px 0px;
  text-align: center;
}
.sect3 h1 {
  font-weight: 700;
  font-size: 32px;
}
.sect3 h2 {
  color: #247ffb;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 25px;
}
.sect3 p {
  font-size: 17px;
  width: 95%;
  margin: auto;
}
.sect3 h1 span {
  color: #5bb3ff;
}
.sect3 .btn_lrn {
  background-color: #38b6ff;
  color: white;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  border-radius: 30px;
  margin-top: 35px !important;
  border: 1px solid #38b6ff;
  padding: 13px 40px;
}
.sect3 .btn_lrn:hover {
  color: #38b6ff;
  background-color: transparent;
  border: 1px solid #38b6ff;
}
.sect4 {
  background-image: url("../../assests/globalimages/residential_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.content {
  padding: 80px 0px;
  text-align: center;
}
.sect3 .prof_crd {
  border: 1px solid #00000033;
  border-radius: 12px;
  padding: 26px 30px;
  margin: 15px 0px;
  box-shadow: 0 2px 3px 0 rgba(36, 127, 251, 0.44),
    0 2px 11px 0 rgba(36, 127, 251, 0.44);
  height: 210px;
  width: 91%;
  margin: auto;
}
.content_about h1 {
  line-height: 51px;
}
.sect3 .prof_crd img {
  height: 100px;
}
.sect4 .prof_crd {
  border: 1px solid #00000033;
  border-radius: 12px;
  padding: 8px 25px;
  background-color: white;
  margin: 15px 0px;
  box-shadow: 0 2px 3px 0 rgba(36, 127, 251, 0.44),
    0 2px 11px 0 rgba(36, 127, 251, 0.44);
  height: 207px;
  width: 91%;
  margin: auto;
}
.sect4 .prof_crd img {
  height: 100px;
  /* width: 100%; */
}
.sect4 .prof_crd p {
  font-size: 16px;
}
.sect4 .content h2 {
  color: rgba(36, 127, 251, 1);
}
.sect4 .btn_lrn {
  background-color: #38b6ff;
  color: white;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  border-radius: 30px;
  margin-top: 15px;
  border: 1px solid #38b6ff;
  padding: 13px 40px;
}
.sect4 .btn_lrn:hover {
  color: #38b6ff;
  background-color: transparent;
  border: 1px solid #38b6ff;
}
.book_now h1 {
  font-size: 38px;
  color: white;
  font-weight: 600;
}
.book_now {
  background: #5bb3ff;
  padding: 50px 20px;
}
.book_now .book_btn {
  background-color: white;
  color: rgba(36, 127, 251, 1);
  font-size: 26px;
  font-weight: 400;
  text-align: center;
  border-radius: 10px;
  font-family: "Prociono", serif;
  /* margin-top: 15px; */
  border: 1px solid white;
  padding: 7px 50px;
}
.about_us {
  padding-top: 80px;
  padding-bottom: 40px;
}
.about_us .btn_lrn {
  background-color: #38b6ff;
  color: white;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  border-radius: 30px;
  margin-top: 15px;
  border: 1px solid #38b6ff;
  padding: 13px 30px;
}
.about_us .btn_lrn:hover {
  color: #38b6ff;
  background-color: transparent;
  border: 1px solid #38b6ff;
}
.about_us .content_about span {
  color: #247ffb;
  margin-bottom: 0px;
}
.about_us .content_about p {
  font-size: 18px;
  line-height: 33px;
}
.about_us .content_about ul {
  list-style: none;
  margin-left: -20px;
}
.about_us .content_about ul li {
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
}
.about_us .content_about ul li i {
  color: #38b6ff;
}

.slick-prev:before,
.slick-next:before {
  color: #09529b !important;
  position: absolute;
  top: 180px;
  font-size: 34px !important;
}
.slick-slider {
  position: relative !important;
}
.slick-next {
  /* right: 0; */
  /* display: flex !important; */
  width: 53% !important;
  justify-content: right !important;
  margin-right: auto !important;
  text-align: left !important;
}
.slick-prev {
  /* left: -15px; */
  text-align: right !important;
  width: 48% !important;
}
.gallery {
  padding: 50px 0px;
}
.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: flex !important;
  gap: 20px;
}
.how_works {
  /* position: relative; */
  padding: 50px 0px;
  background-image: linear-gradient(#38b6ff, #38b6ff);
  /* background-color: #38b6ff; */
  /* border-radius; */
  color: white;
  text-align: center;
  background-repeat: no-repeat;
  background-size: 100% 70%;
  background-position: top left;
  /* height: 60vh; */
  /* margin-bottom: 120px; */
}
.how_works .wrk_cards .wrk_crd_parnt {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}
.how_works .wrk_cards {
  text-align: center;
  display: flex;
  justify-content: center;
  /* position: absolute; */
  /* bottom: -100px; */
}
.how_works .wp {
  font-size: 20px;
}
.how_works .work_crd {
  width: 300px;
  height: 270px;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 10px;
  text-align: center;
  padding: 25px;
  background-color: white;
  color: black;
}
.how_works .work_crd h3 {
  font-size: 25px;
  font-weight: 600;
}
.how_works .work_crd .wpc {
  font-size: 17px;
  text-align: center;
  padding: 0px 10px;
}
.service {
  background-image: url("../../assests/globalimages/service.png");
  background-size: cover;
  padding: 100px 0px;
}
.service .service_crd {
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: white;
  padding: 32px;
  /* width: 450px; */
  border-radius: 20px;
  text-align: center;
}
.service .service_crd p {
  font-size: 18px;
}
.service .btn_lrn {
  background-color: #437ffc;
  color: white;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  border-radius: 10px;
  margin-top: 15px;
  border: 1px solid #437ffc;
  padding: 13px 30px;
}
.service .btn_lrn:hover {
  color: #437ffc;
  background-color: transparent;
  border: 1px solid #437ffc;
}
.footer {
  position: relative;
  margin-top: 120px;

  background-image: linear-gradient(#38b6ff, #38b6ff);

  background-repeat: no-repeat;
  background-size: 100% 1%;
  background-position: bottom top;
}
.footer-top {
  padding: 20px 125px;
  background-color: #fdbe4a;
  border-radius: 6px;
  position: absolute;
  top: -50px;
}
.bb-footer {
  background-color: #38b6ff;
  color: white;
  padding: 10px 30px;
  border: 1px solid #38b6ff;
}
.bb-footer:hover {
  background-color: transparent;
  border: 1px solid #38b6ff;
  color: #38b6ff;
}
.main-ftr {
  background: var(--dodger-blue);
  padding-top: 35px;
  padding-bottom: 30px;
}

.ftr_top {
  width: 100%;
  /* margin-bottom: 25px; */
}
.ftr_top .h33 {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: white;
}
.ftr_top .hh3 {
  font-size: 18px;
  font-weight: 400;
  color: white;
}

.footer-logo-w {
  height: 100%;
}

.ftr_top h4 {
  font-size: 18px;
  color: white;
  font-weight: 400;
}

.ftr_top i {
  margin-right: 10px;

  color: var(--freshair);
}

.ftr_top h2 {
  font-size: 23px;

  font-weight: 500;

  color: var(--freshair);

  line-height: 1.3em;

  margin-bottom: 10px;
}

.ftr_top ul {
  list-style: none;

  margin: 0px;

  padding: 0px;

  text-transform: none;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: normal;
}

.ftr_top ul li {
  float: left;

  width: 100%;

  margin-bottom: 10px;
}

.ftr_top ul li a {
  font-size: 14px;

  color: var(--freshair);
}

.ftr_top p a {
  color: var(--freshair);
}
/* modal */
.contactfrm-popup-main .modal-dialog button.close {
  position: absolute;

  top: -22px;

  right: -22px;

  padding: 0;

  width: 3.063rem;

  height: 3.063rem;

  border: solid 5px #1e1e51;

  background: white;

  border-radius: 50%;

  color: #1e1e51;

  text-align: center;

  font-family: initial;

  font-size: 30px;

  opacity: 1;

  line-height: normal;

  z-index: 1;

  text-shadow: 0 0 0;

  cursor: pointer;
}

.contactfrm-popup-main {
  padding: 0 !important;
}
.form-label {
  margin-bottom: 0.5rem;
}
.contactfrm_pop h2 {
  color: #1e1e51;
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  font-family: var(--source-sans-pro);
  text-transform: capitalize;
  line-height: 1.5;
  margin-bottom: 20px;
}
.contactfrm_pop label {
  font-weight: 700;
  color: #1e1e51;
}

.contactfrm_pop ul li {
  float: left;

  width: 100%;

  margin-bottom: 15px;
}

.contactfrm_pop ul li label {
  color: #000000;

  font-size: 18px;

  margin-bottom: 8px;

  font-family: var(--source-sans-pro);

  font-weight: 500;

  float: left;

  width: 100%;
}

.contactfrm_pop ul li input {
  float: left;

  width: 100%;

  font-size: 15px;

  padding: 0 10px;

  font-size: 15px;

  border: 1px solid black;

  height: 40px;

  color: black;
}

#submitBtn {
  color: white;
  width: 53%;
  cursor: pointer;
  border-radius: 0;
  margin-top: 5px;
  background-color: #1e1e51;
  text-decoration: none;
  padding: 6px 0px 6px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 10px;
  border-color: #1e1e51;
  border-width: 2px;
  border-style: solid;
  letter-spacing: 0;
  text-transform: none;
  text-shadow: 0 0 0 transparent;
}
#successPopup .modal-header {
  justify-content: space-between;
}
@media (max-width: 1190px) {
  .sect3 .prof_crd img {
    height: 82px;
  }
  .sect3 .prof_crd {
    padding: 26px 11px;
  }
}
@media (max-width: 990px) {
  .sect2 h1 {
    font-size: 32px;
  }
  .sect3 .prof_crd img {
    height: 62px;
  }
  .sect3 .prof_crd {
    padding: 26px 17px;
  }
  .navbar-nav {
    gap: 0px !important;
  }
  .btn_ul li {
    display: flex;
    justify-content: center;
  }
  .sect2 h3 {
    font-size: 25px;
  }
  .book_now h1 {
    font-size: 28px;
    text-align: center;
  }
  .book_now .book_btn {
    font-size: 24px;
  }
  .slick-prev:before,
  .slick-next:before {
    top: 105px;
  }
  .cen {
    display: flex;
    justify-content: center !important;
  }
}
@media (max-width: 1190px) {
  .sect4 .prof_crd {
    height: auto;
  }
}
@media (max-width: 768px) {
  .arw_img {
    display: none;
  }
  .work_crd img {
    width: 90px;
    margin-bottom: 10px;
  }
  .how_works .work_crd h3 {
    font-size: 22px;
  }
  .sect3 .prof_crd {
    width: 250px;
    margin: auto;
  }
  .sect4 .prof_crd {
    border: 1px solid #00000033;
    border-radius: 12px;
    padding: 20px 25px;
    margin: 15px 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    /* height: 250px; */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 256px;
    margin: auto;
  }
  .slick-prev:before,
  .slick-next:before {
    top: 182px;
  }
  .navbar img {
    max-width: 50px !important;
  }
  .how_works .work_crd {
    width: 278px;
    height: 270px;
    border-radius: 10px;
    padding: 20px;
  }
  .banner-section {
    margin-top: 70px;
  }
  .footer-top {
    padding: 20px 40px;
  }
  .sect2 h1 {
    font-size: 28px;
  }
  .banner-section {
    height: 600px;
  }
  .how_works {
    background-size: 100% 100%;
  }
}
@media (max-width: 720px) {
  .banner-section {
    padding: 0px 75px;
  }
}
@media (max-width: 550px) {
  .banner-section {
    padding: 0px 18px;
  }
  .hme_bnr_text h1 {
    font-size: 38px;
  }
  .service .service_crd h2 {
    font-size: 22px;
  }
  .service .service_crd p {
    font-size: 16px;
  }
  .see_how_w {
    font-size: 16px;
    padding: 2px 28px !important;
    height: 45px !important;
  }
  .service_btn {
    height: 52px;
  }
  .sect2 h1 {
    font-size: 23px;
  }

  .slick-prev:before,
  .slick-next:before {
    top: 186px;
  }
  .book_now h1 {
    font-size: 24px;
    text-align: center;
  }
  .book_now .book_btn {
    font-size: 20px;
    padding: 7px 36px;
  }
}
@media (max-width: 460px) {
  .hme_bnr_text h1 {
    font-size: 28px;
    line-height: 33px;
  }
  .hme_bnr_text p {
    width: 95%;
    font-size: 20px;
  }
  .banner-section {
    height: 500px;
  }
  .wre_sect2 {
    /* flex-wrap: wrap; */
    flex-direction: column;
    gap: 10px !important;
  }
  .slick-prev:before,
  .slick-next:before {
    top: 171px;
  }
}
@media (max-width: 340px) {
  .hme_bnr_text h1 {
    font-size: 24px;
    line-height: 33px;
  }
  .hme_bnr_text p {
    width: 100%;
    font-size: 20px;
  }
  .see_how_w {
    font-size: 16px;
    padding: 2px 28px !important;
    height: 45px !important;
  }
  .service_btn {
    height: 50px;
    font-size: 14px;
    padding: 7px 13px !important;
  }
  .sect3 h1 {
    font-weight: 700;
    font-size: 28px;
  }
  .sect3 p {
    font-size: 16px;
    width: 95%;
    margin: auto;
    /* text-align: justify; */
  }
  .book_now h1 {
    font-size: 22px;
    text-align: center;
  }
  .about_us h1 {
    font-size: 26px;
  }
  .about_us .content_about p {
    font-size: 16px;
  }
  .about_us .content_about ul li {
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
  }
  .how_works .wp {
    font-size: 16px;
  }
  .slick-prev:before,
  .slick-next:before {
    top: 120px;
  }
}
